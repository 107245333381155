import React from 'react';
import { Link } from "react-router-dom";

const Failed = () => {
  return (
    <div>
      <h1>No pudimos procesar su compra, intentelo de nuevo</h1>
      <p>Link</p>
    </div>
  )
}

export default Failed
