import './styles/hamburguer.css';

const Hamburguer = () => {
    return (
        <div className="hamburguer">
            <div className="burguer burguer1"></div>
            <div className="burguer burguer 2"></div>
            <div className="burguer burguer 3"></div>
        </div>
    )
}

export default Hamburguer;
